import {
  BlokkieIconPill,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

interface InfoItemProps {
  icon: string;
  children: React.ReactNode;
  dataTestId: string;
}

function InfoItem({ icon, children, dataTestId }: InfoItemProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div data-test-id={dataTestId} className={classes.container}>
      <BlokkieIconPill
        size="xlarge"
        color={blokkieTheme.colors.grey.veryLight}
        shade="light"
      >
        {icon}
      </BlokkieIconPill>
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: blokkieTheme.spacing(2),
    marginTop: blokkieTheme.spacing(2),
  },
});

export default InfoItem;
