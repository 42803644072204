import React from 'react';
import { BlokkieButton, BlokkieTypography } from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import '!file-loader?name=[name].[ext]!../../../images/gifs/the-office-sorry.gif';
import Screen from './Screen';

const abnUrl =
  'https://www.abnamro.nl/nl/zakelijk/producten/zakelijke-rekening/index.html';
const formUrl = 'https://www.tikkie.me/zakelijk/aanvragen';

export type ErrorType = 'generic' | 'legalEntityNotAllowed';

interface RejectWithReferErrorScreenProps {
  errorType?: ErrorType;
}

function RejectWithReferErrorScreen({
  errorType = 'generic',
}: RejectWithReferErrorScreenProps): JSX.Element {
  const { t } = useTranslation();

  const referTo = () => {
    const url = errorType === 'legalEntityNotAllowed' ? formUrl : abnUrl;
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Screen
      showGif
      header={t(`onboarding.fluentflow.riskQuestion.error.${errorType}.header`)}
      sadGifVersion
    >
      <BlokkieTypography
        variant="pl500"
        data-test-id="Onboarding-Rejection-Body"
        style={{ textAlign: 'center' }}
      >
        {t(`onboarding.fluentflow.riskQuestion.error.${errorType}.body`)}
      </BlokkieTypography>
      <BlokkieButton
        data-test-id="Onboarding-Rejection-ReferButton"
        onClick={referTo}
        size="large"
        variant="primary"
        icon="arrowOutOblique"
        style={{ marginTop: 'auto' }}
      >
        {t(`onboarding.fluentflow.riskQuestion.error.${errorType}.button`)}
      </BlokkieButton>
    </Screen>
  );
}

export default RejectWithReferErrorScreen;
