import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import {
  handleCurrencyKeyDown,
  isMoreThanTwoDecimals,
} from '../Util/inputFieldHelpers';
import { Color, PrimaryButton, SecondaryButton, Shadow } from '../styleguide';
import InputSuggestion from './InputSuggestion';
import Tooltip from '../Tooltip/Tooltip';
import '!file-loader?name=[name].[ext]!../../../src/images/suggestions-example.png';

interface SuggestionsProps {
  onGenerate(queryParameters: string): void;
  onInputChange(): void;
  onReset(): void;
}

const initSuggestionsState = {
  option1: {
    display: '',
    value: 0,
  },
  option2: {
    display: '',
    value: 0,
  },
  option3: {
    display: '',
    value: 0,
  },
};

function Suggestions({
  onGenerate,
  onInputChange,
  onReset,
}: SuggestionsProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const option1Name = 'option1';
  const option2Name = 'option2';
  const option3Name = 'option3';
  const [suggestions, setSuggestions] = useState(initSuggestionsState);
  const [generated, setGenerated] = useState(false);

  const generateQueryParameters = (): string => {
    const rawSuggestions = [
      suggestions.option1.value,
      suggestions.option2.value,
      suggestions.option3.value,
    ];
    const removeEmptySuggestions = rawSuggestions.filter((item) => item > 0);
    const finalLink: string[] = removeEmptySuggestions.map((value) =>
      fixDecimals(value),
    );
    setGenerated(true);
    return finalLink.toString();
  };

  const fixDecimals = (value: number): string => {
    const dec = value.toString().split('.')[1];
    if (dec && dec.length === 1) {
      return Number(value).toFixed(2);
    }
    return value.toString();
  };

  const isSuggestionsValid = () =>
    [
      suggestions.option1.value,
      suggestions.option2.value,
      suggestions.option3.value,
    ].filter(Boolean).length > 1;

  const hasNoInputFilled = () =>
    [
      suggestions.option1.value,
      suggestions.option2.value,
      suggestions.option3.value,
    ].filter(Boolean).length === 0;

  const onKeyHandler = (name: string, event: FormEvent<HTMLInputElement>) => {
    handleCurrencyKeyDown(
      event,
      suggestions[name as keyof typeof initSuggestionsState].display,
    );
  };

  const handleUpdate = (key: string, value: string) => {
    if (isMoreThanTwoDecimals(value)) return;
    const numericValue: number = +value.replace(',', '.');
    if (numericValue > 9999.99) return;
    setSuggestions((suggestions) => ({
      ...suggestions,
      [key]: {
        display: value.replace('.', ','),
        value: numericValue,
      },
    }));
    onInputChange();
    setGenerated(false);
  };

  const resetSuggestions = () => {
    setSuggestions(initSuggestionsState);
    onReset();
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div>
          <BlokkieTypography
            variant="h3"
            style={{
              color: Color.gray01,
            }}
          >
            {t('portal.shareTikkie.suggestion.header')}
          </BlokkieTypography>
        </div>
        <Tooltip
          style={{ right: '-33%', transform: 'unset' }}
          arrowStyle={{ left: '92.5%' }}
          position="top"
          isButton
        >
          <BlokkieTypography
            variant="pl500"
            style={{
              color: Color.gray01,
            }}
          >
            {t('portal.shareTikkie.suggestion.giveInfo')}
          </BlokkieTypography>
        </Tooltip>
      </div>
      <div className={classes.box}>
        <div className={classes.row}>
          <div className={classes.title}>
            <div className={classes.labelContainer}>
              <BlokkieTypography
                variant="pl700"
                style={{
                  color: Color.gray01,
                  lineHeight: 1.2,
                }}
              >
                {t('portal.shareTikkie.suggestion.give')}
              </BlokkieTypography>
            </div>
            <Tooltip
              style={{ left: '-30%', right: 'unset', transform: 'unset' }}
              arrowStyle={{ left: '18%' }}
              position="top"
              dataTestId="what-is-this-tooltip"
            >
              <div
                style={{
                  marginBottom: blokkieTheme.spacing(1),
                }}
              >
                <BlokkieTypography
                  variant="pl700"
                  style={{
                    color: Color.gray01,
                  }}
                  data-test-id="what-is-this-title"
                >
                  {t('portal.shareTikkie.suggestion.whatIsThisInfo.title')}
                </BlokkieTypography>
              </div>
              <div>
                <BlokkieTypography
                  variant="pl500"
                  style={{
                    color: Color.gray01,
                    marginBottom: blokkieTheme.spacing(1),
                  }}
                >
                  {t(
                    'portal.shareTikkie.suggestion.whatIsThisInfo.description',
                  )}
                </BlokkieTypography>
              </div>
              <div className={classes.exampleImageContainer}>
                <img src="/suggestions-example.png" alt="" />
              </div>
            </Tooltip>
          </div>
          <div className={classes.inputContainer}>
            <InputSuggestion
              name={option1Name}
              value={suggestions.option1.display}
              onChange={handleUpdate}
              onKeyHandler={onKeyHandler}
              label="1."
              dataTestId="suggestions-input-1"
            />
            <InputSuggestion
              name={option2Name}
              value={suggestions.option2.display}
              onChange={handleUpdate}
              onKeyHandler={onKeyHandler}
              label="2."
              dataTestId="suggestions-input-2"
            />
            <InputSuggestion
              name={option3Name}
              value={suggestions.option3.display}
              onChange={handleUpdate}
              onKeyHandler={onKeyHandler}
              label="3."
              dataTestId="suggestions-input-3"
            />
          </div>
          <div className={classes.buttonsContainer}>
            <SecondaryButton
              data-test-id="suggestions-reset-button"
              rounded
              style={{
                display: 'flex',
                height: blokkieTheme.spacing(6),
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={resetSuggestions}
              secondary
              disabled={hasNoInputFilled()}
            >
              <img src="/trash.svg" alt="" />
              {t('delete')}
            </SecondaryButton>
            <PrimaryButton
              data-test-id="suggestions-generate-button"
              style={{
                height: blokkieTheme.spacing(6),
              }}
              onClick={() => onGenerate(generateQueryParameters())}
              primary
              disabled={generated || !isSuggestionsValid()}
            >
              {t('portal.shareTikkie.suggestion.useButton')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  wrapper: {
    marginBottom: blokkieTheme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: blokkieTheme.spacing(5),
    marginBottom: blokkieTheme.spacing(3),
  },
  box: {
    backgroundColor: blokkieTheme.colors.grey.white,
    boxShadow: Shadow,
    borderRadius: blokkieTheme.spacing(1),
    padding: blokkieTheme.spacing(4),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [blokkieTheme.mediaQueries.allPhone]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 158px',
    [blokkieTheme.mediaQueries.allPhone]: {
      flex: 'unset',
      marginBottom: blokkieTheme.spacing(4),
    },
  },
  labelContainer: {
    '& span': {
      fontSize: 20,
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      '& span': {
        fontSize: 22,
      },
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: blokkieTheme.spacing(2),
    [blokkieTheme.mediaQueries.tablet]: {
      flexDirection: 'column',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      flexDirection: 'column',
      marginBottom: blokkieTheme.spacing(4),
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: blokkieTheme.spacing(2),
    [blokkieTheme.mediaQueries.tablet]: {
      flexDirection: 'column',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  exampleImageContainer: {
    marginTop: blokkieTheme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Suggestions;
