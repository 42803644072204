import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import {
  tikkieCashback,
  tikkieRecycling,
} from '../../../containers/OnboardingKYCContainer/config';
import { useOnboardingPageView } from '../../../hooks';
import ButtonFooter from '../components/ButtonFooter';
import NavigationHeader from '../components/NavigationHeader';
import Title from '../Title';
import InfoItem from './infoItem';
import { Shade } from '../styledComponents';

interface ProcessExplanationProps {
  nextStep: () => void;
  previousStep: () => void;
  forSecondApplicant: boolean;
  aabOnboarding: boolean;
  isCddFlow: boolean;
  productType: string;
}

function ProcessExplanation({
  nextStep,
  previousStep,
  forSecondApplicant,
  aabOnboarding,
  isCddFlow,
  productType,
}: ProcessExplanationProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  useOnboardingPageView('process-explanation');
  const showOneCentExplanation =
    aabOnboarding ||
    !(
      productType === tikkieCashback ||
      productType === tikkieRecycling ||
      forSecondApplicant
    );

  const showSoleExplanation = isCddFlow;

  return (
    <>
      <NavigationHeader noMarginBottom clickLeft={previousStep as () => void} />
      <div className={classes.scrollContainer}>
        <Title>{t('onboarding.processExplanation.titleNeeded')}</Title>
        <div className={classes.infoItemContainer}>
          {showSoleExplanation && (
            <InfoItem icon="🏢" dataTestId="Onboarding-ProcessExplanation-Sole">
              <BlokkieTypography variant="pl500">
                {t('onboarding.processExplanation.sole')}
              </BlokkieTypography>
            </InfoItem>
          )}

          <InfoItem icon="✍️" dataTestId="Onboarding-ProcessExplanation-Sole">
            <BlokkieTypography variant="pl500">
              {t('onboarding.processExplanation.contract')}
            </BlokkieTypography>
          </InfoItem>

          <InfoItem icon="🪪" dataTestId="Onboarding-ProcessExplanation-Sole">
            <div className={classes.soleInfoContainer}>
              <BlokkieTypography variant="pl500">
                {t('onboarding.processExplanation.identity.part1')}{' '}
              </BlokkieTypography>
              <BlokkieTypography
                className={classes.identityInfoText}
                variant="pl700"
              >
                {t('onboarding.processExplanation.identity.part2')}
              </BlokkieTypography>
              <BlokkieTypography variant="pl500">
                {' '}
                {t('onboarding.processExplanation.identity.part3')}
              </BlokkieTypography>
            </div>
          </InfoItem>

          {showOneCentExplanation && (
            <InfoItem icon="💶" dataTestId="Onboarding-ProcessExplanation-Sole">
              <BlokkieTypography variant="pl500">
                {t('onboarding.processExplanation.oneCent')}
              </BlokkieTypography>
            </InfoItem>
          )}
        </div>
      </div>
      <ButtonFooter shadeHeight={20}>
        <BlokkieButton
          variant="primary"
          size="large"
          onClick={nextStep}
          data-test-id="Onboarding-ProcessExplanation-NextButton"
        >
          {t('onboarding.processExplanation.nextButton')}
        </BlokkieButton>
      </ButtonFooter>
    </>
  );
}

const useStyles = createUseStyles({
  infoItemContainer: {
    marginTop: blokkieTheme.spacing(3),
    marginBottom: blokkieTheme.spacing(3),
  },
  soleInfoContainer: {
    display: 'block',
  },
  identityInfoText: {
    textDecoration: 'underline',
  },
  scrollContainer: {
    height: 400,
    textAlign: 'left',
    overflowY: 'scroll',
    margin: `0 ${-blokkieTheme.spacing(4)}px`,
    marginRight: `${-blokkieTheme.spacing(2)}px`,
    padding: `0 ${blokkieTheme.spacing(4)}px`,
    [blokkieTheme.mediaQueries.allPhone]: {
      height: 'auto',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#B9B9CA',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#B9B9CA',
    },
  },
});

export default injectIntl<ProcessExplanationProps>(ProcessExplanation);
