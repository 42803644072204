export const availableBanksFallBackList = [
  {
    value: 'ABNANL2A',
    label: 'ABN AMRO',
  },
  {
    value: 'INGBNL2A',
    label: 'ING',
  },
  {
    value: 'RABONL2U',
    label: 'Rabobank',
  },
  {
    value: 'ASNBNL21',
    label: 'ASN Bank',
  },
  {
    value: 'BUNQNL2A',
    label: 'bunq',
  },
  {
    value: 'HANDNL2A',
    label: 'Handelsbanken',
  },
  {
    value: 'KNABNL2H',
    label: 'Knab',
  },
  {
    value: 'MOYONL21',
    label: 'Moneyou',
  },
  {
    value: 'RBRBNL21',
    label: 'RegioBank',
  },
  {
    value: 'SNSBNL2A',
    label: 'SNS',
  },
  {
    value: 'TRIONL2U',
    label: 'Triodos Bank',
  },
  {
    value: 'FVLBNL22',
    label: 'van Lanschot',
  },
  {
    value: 'REVOLT21',
    label: 'Revolut',
  },
];

export type BankOption = {
  value: string;
  label: string;
};
