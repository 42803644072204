import { blokkieTheme } from '@tikkie/blokkie';
import React, { Children } from 'react';
import { createUseStyles } from 'react-jss';
import { Shade } from '../styledComponents';

interface IProps {
  children: React.ReactNode;
  id?: string;
  align?: 'left' | 'right';
  forceAlign?: 'left' | 'right' | 'space-between' | 'center';
  shadeHeight?: number;
  customStyle?: React.CSSProperties;
  customClass?: string;
}

function ButtonFooter(props: IProps): JSX.Element {
  const classes = useStyles();
  const {
    children,
    align,
    forceAlign,
    customStyle,
    id,
    customClass,
    shadeHeight,
  } = props;
  const childrenCount = Children.count(children);
  const singleAlignmentClass =
    align === 'left' ? classes.leftAlign : classes.rightAlign;

  return (
    <>
      {shadeHeight && (
        <Shade minHeight={shadeHeight} marginTop={-shadeHeight} />
      )}
      <div
        id={id ?? 'button-footer'}
        className={[
          customClass,
          classes.container,
          childrenCount > 1 && !forceAlign
            ? classes.spaceBetween
            : singleAlignmentClass,
        ].join(' ')}
        style={{
          justifyContent: forceAlign ?? '',
          ...customStyle,
        }}
      >
        {children}
      </div>
    </>
  );
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    alignItems: 'flex-end',
  },
  leftAlign: {
    justifyContent: 'left',
  },
  rightAlign: {
    justifyContent: 'right',
  },
  spaceBetween: {
    justifyContent: 'space-between',
    '&.summary': {
      [blokkieTheme.mediaQueries.allPhone]: {
        flexDirection: 'column-reverse',
        gap: blokkieTheme.spacing(),
      },
    },
    '&.verificationCode': {
      [blokkieTheme.mediaQueries.allPhone]: {
        flexDirection: 'column',
        gap: blokkieTheme.spacing(),
      },
    },
  },
});

export default ButtonFooter;
