import { Flow } from '../../components/OnboardingKYC/enums/Flow';

// This is the final master flow that is determined possibly by:
// - the path of the url, e.g. /second-applicant/
// - a hashtag in the url
// - the flow stored in the onboarding file
export enum OnboardingFlow {
  UNDETERMINED = 'undetermined', // before bank selection and no hash
  BASIC = 'basic',
  INVITE = 'invite',
  CASHBACK = 'invite-cashback',
  RECYCLING = 'invite-recycling',
  AAB_ONBOARDING = 'aabonboarding',
  SECOND_APPLICANT = 'secondapplicant-invite',
  CDD_ONBOARDING = 'cdd',
}

export enum OnboardingHash {
  NONE = '',
  INVITE = '#invite',
  CASHBACK = '#invite-cashback',
  RECYCLING = '#invite-recycling',
  AAB_ONBOARDING = '#aabonboarding',
  CDD_PILOT = '#invite-pilot',
}

export const hashToOnboardingFlowMapping = new Map([
  [OnboardingHash.INVITE, OnboardingFlow.INVITE],
  [OnboardingHash.CASHBACK, OnboardingFlow.CASHBACK],
  [OnboardingHash.RECYCLING, OnboardingFlow.RECYCLING],
  [OnboardingHash.AAB_ONBOARDING, OnboardingFlow.AAB_ONBOARDING],
  [OnboardingHash.CDD_PILOT, OnboardingFlow.CDD_ONBOARDING],
]);

export const onboardingFlowToHashMapping = new Map([
  [OnboardingFlow.UNDETERMINED, OnboardingHash.NONE],
  [OnboardingFlow.BASIC, OnboardingHash.NONE],
  [OnboardingFlow.INVITE, OnboardingHash.INVITE],
  [OnboardingFlow.CASHBACK, OnboardingHash.CASHBACK],
  [OnboardingFlow.RECYCLING, OnboardingHash.RECYCLING],
  [OnboardingFlow.AAB_ONBOARDING, OnboardingHash.AAB_ONBOARDING],
  [OnboardingFlow.CDD_ONBOARDING, OnboardingHash.CDD_PILOT],
]);

export const flowToOnboardingFlow = new Map([
  [Flow.BUSINESS_ABN_AMRO, OnboardingFlow.BASIC],
  [Flow.BUSINESS_CDD, OnboardingFlow.CDD_ONBOARDING],
  [Flow.BUSINESS_INVITE, OnboardingFlow.INVITE],
  [Flow.CASHBACK_INVITE, OnboardingFlow.CASHBACK],
  [Flow.RECYCLING_INVITE, OnboardingFlow.RECYCLING],
]);
