/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
  useSnackBar,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '!file-loader?name=[name].[ext]!../../../images/videoGifs/thumbs-up.mp4';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/hardworking-cat.mp4';

import TikkieTerms from '../Agreement/TikkieTerms';

import { TERMS_URL } from '../Agreement/config';
import ButtonFooter from '../components/ButtonFooter';
import CostRectangles from '../Agreement/CostRectangles';
import {
  selectContactData,
  selectEmail,
  selectPhoneNumber,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import { capitalizeAndRemoveSpaces, formatDateToPattern } from '../../../utils';
import Loader from '../Loader';
import {
  useOnboardingPageView,
  useOnScrollBottom,
  useOnScroll,
} from '../../../hooks';

interface SummaryProps {
  terms: string;
  isGettingTerms: boolean;
  getTerms(): void;
  acceptTerms(): void;
}

function Summary({
  terms,
  isGettingTerms,
  getTerms,
  acceptTerms,
}: SummaryProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const contactData = useSelector(selectContactData);
  const email = useSelector(selectEmail);
  const phoneNumber = useSelector(selectPhoneNumber);
  const productType = useSelector(selectProductType);

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrolledToBottom = useOnScrollBottom({
    scrollRef,
    dataLoaded: !isGettingTerms,
    threshold: blokkieTheme.spacing(2.5),
  });
  const scrolled = useOnScroll({
    scrollRef,
    dataLoaded: !isGettingTerms,
  });

  useOnboardingPageView('terms-summary');
  const { addAlert, removeAlert } = useSnackBar();

  useEffect(() => {
    if (productType) {
      getTerms();
    }
  }, [productType]);

  useEffect(() => {
    if (isMobileOnly) {
      addAlert({
        message: t('onboarding.summary.snackbar'),
        timeout: 0,
        bottom: '140px',
      });
    }
  }, []);

  useEffect(() => {
    if (isMobileOnly && scrolled) {
      removeAlert();
    }
  }, [scrolled]);

  const buildLastName = () => {
    if (!contactData) return;
    if (contactData.lastNamePrefix) {
      return `${contactData.lastNamePrefix} ${contactData.lastName}`;
    }
    return contactData.lastName;
  };

  const buildAddress = () => {
    if (contactData) {
      return `${contactData.address.street} ${
        contactData.address.houseNumber
      }, ${contactData.address.postalCode} ${capitalizeAndRemoveSpaces(
        contactData.address.city,
      )}`;
    }
  };

  const contactDataMap: { key: string; value: string }[] = [
    {
      key: t('onboarding.summary.contactData.firstName'),
      value: contactData.firstName,
    },
    {
      key: t('onboarding.summary.contactData.lastName'),
      value: buildLastName(),
    },
    {
      key: t('onboarding.summary.contactData.gender'),
      value: capitalizeAndRemoveSpaces(contactData.gender),
    },
    {
      key: t('onboarding.summary.contactData.birthDate'),
      value: formatDateToPattern(contactData.birthDate, 'DD-MM-YYYY'),
    },
    { key: t('onboarding.summary.contactData.email'), value: email },
    { key: t('onboarding.summary.contactData.phone'), value: phoneNumber },
    { key: t('onboarding.summary.contactData.address'), value: buildAddress() },
  ];

  const emailSubject = encodeURI(
    t('onboarding.summary.buttons.mailto.subject'),
  );

  const [values, setValues] = useState({
    showCosts: false,
    showTerms: false,
  });

  const toggleShowTerms = (): void =>
    setValues((prevState) => ({
      ...prevState,
      showCosts: false,
      showTerms: !prevState.showTerms,
    }));

  if (isGettingTerms) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.navigationHeader} />
      <div ref={scrollRef} className={classes.scrollContainer}>
        <div className={classes.summaryContainer}>
          <div>
            <BlokkieTypography variant="h3">
              {t('onboarding.summary.title.part1')}
            </BlokkieTypography>
            <BlokkieTextLink
              variant="h3"
              underline
              onClick={toggleShowTerms}
              color={blokkieTheme.colors.grey.veryDark}
              data-test-id="terms-open-terms-info-screen"
            >
              {t('onboarding.summary.title.part2')}
            </BlokkieTextLink>
            <BlokkieTypography variant="h3">
              {t('onboarding.summary.title.part3')}
            </BlokkieTypography>
            <BlokkieTextLink
              href={TERMS_URL}
              target="_blank"
              variant="h3"
              underline
              color={blokkieTheme.colors.grey.veryDark}
            >
              {t('onboarding.summary.title.part4')}
            </BlokkieTextLink>
            <BlokkieTypography variant="h3">
              {t('onboarding.summary.title.part5')}
            </BlokkieTypography>
          </div>

          <div className={classes.contactData}>
            <BlokkieTypography
              variant="pl700"
              data-test-id="Onboarding-Summary-PersonalDetails"
            >
              {t('onboarding.summary.contactData.title')}
            </BlokkieTypography>

            {contactDataMap.map((contactDataDetail) => (
              <div className={classes.detail} key={contactDataDetail.key}>
                <BlokkieTypography
                  variant="pl700"
                  color={blokkieTheme.colorMap.business.text.secondary.default}
                  style={{ marginBottom: blokkieTheme.spacing() }}
                >
                  {contactDataDetail.key}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="pl700"
                  color={blokkieTheme.colorMap.business.text.accent.contrast}
                >
                  {contactDataDetail.value}
                </BlokkieTypography>
              </div>
            ))}
          </div>
          <CostRectangles />
          <TikkieTerms
            show={values.showTerms}
            toggleShowTerms={toggleShowTerms}
            terms={terms}
          />
        </div>
      </div>
      <ButtonFooter
        shadeHeight={60}
        customClass="summary"
        customStyle={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BlokkieButton
          onClick={() => {
            window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}?subject=${emailSubject}`;
          }}
          data-test-id="Onboarding-Summary-OpenEmail"
          variant="secondary"
          style={{ padding: 0 }}
        >
          {t('onboarding.summary.buttons.mail')}
        </BlokkieButton>
        <BlokkieButton
          disabled={!scrolledToBottom}
          onClick={acceptTerms}
          data-test-id="Onboarding-Summary-AgreeButton"
          variant="primary"
          size="large"
        >
          {t('onboarding.summary.buttons.agree')}
        </BlokkieButton>
      </ButtonFooter>
    </>
  );
}

const useStyles = createUseStyles({
  navigationHeader: {
    height: blokkieTheme.spacing(6),
    paddingBottom: blokkieTheme.spacing(2),
    // overwrite toplevel styling in formwrapper
    marginTop: -blokkieTheme.spacing(),
  },
  scrollContainer: {
    // height = 480 container - 48 header - 105 footer - 24 padding
    height: 303,
    textAlign: 'left',
    overflowY: 'scroll',
    margin: `0 ${-blokkieTheme.spacing(4)}px`,
    marginRight: `${-blokkieTheme.spacing(2)}px`,
    padding: `0 ${blokkieTheme.spacing(4)}px`,
    [blokkieTheme.mediaQueries.allPhone]: {
      height: 'auto',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#B9B9CA',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#B9B9CA',
    },
  },
  summaryContainer: {
    marginBottom: blokkieTheme.spacing(2.5),
  },
  contactData: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: blokkieTheme.spacing(2),
    margin: `${blokkieTheme.spacing(3)}px 0`,
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default Summary;
