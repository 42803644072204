import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useTranslation } from 'react-i18next';
import { BlokkieButton, blokkieTheme } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { isMobileOnly } from 'react-device-detect';
import { Wrapper } from '../styledComponents';
import Loader from '../Loader';
import NavigationHeader from '../components/NavigationHeader';
import { ErrorBubble } from '../ErrorBubble';
import { InfoBubble } from '../InfoBubble';
import ButtonFooter from '../components/ButtonFooter';
import InputKeyboard from '../components/InputKeyboard';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IProps {
  prevStep: () => void;
  nextStep: () => void;
  phoneNumber: string;
  resendSms: (phoneNumber: string) => void;
  resendingSms: boolean;
  isSmsResent: boolean;
  verifyCode: (code: string) => void;
  hasError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

function VerificationCode(props: IProps): JSX.Element {
  const { t } = useTranslation();
  const {
    prevStep,
    nextStep,
    phoneNumber,
    resendSms,
    resendingSms,
    isSmsResent,
    verifyCode,
    hasError,
    error,
  } = props;
  const [code, setCode] = useState('');
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const onResend = (): void => resendSms(phoneNumber);

  const disableButton = (): boolean => code.length < 4;

  useOnboardingPageView('verification-code');

  useEffect(() => {
    if (hasError && error.errorCode === 2082)
      setErrorMessage(t('onboarding.verifyPhone.error2082'));
    if (hasError && error.errorCode === 2078)
      setErrorMessage(t('onboarding.verifyPhone.error2078'));
  }, [hasError, error]);

  const next = (): void => {
    verifyCode(code);
    nextStep();
  };

  const onHandleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const c = event.key;
    const { keyCode } = event;
    // Backspace is OK
    if (keyCode === 8) {
      return;
    }

    // Enter
    if (keyCode === 13 && !disableButton()) {
      next();
    } else if ('0123456789'.indexOf(c) === -1 || code.length > 3) {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <NavigationHeader clickLeft={prevStep as () => void} />
      <Title>{t('onboarding.verifyPhone.title')}</Title>
      <InputKeyboard
        placeholder="0000"
        type="number"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        onKeyDown={onHandleKeyDown}
        autoFocus
        data-test-id="Onboarding-VerificationCode-Input"
        relatedButtonId="button-footer"
        style={{ letterSpacing: '6px' }}
      />

      {resendingSms && <Loader />}
      {!isMobileOnly && (
        <div className={classes.verifyPhoneBodyWeb}>
          <div>
            {errorMessage && <ErrorBubble errorMessage={errorMessage} />}
          </div>
          <div>
            <div style={{ display: 'inline-block' }}>
              {isSmsResent && (
                <InfoBubble text={t('onboarding.verifyPhone.resentCode')} />
              )}
            </div>
            {!resendingSms && (
              <ButtonFooter customClass="verificationCode">
                <BlokkieButton
                  variant="secondary"
                  size="large"
                  onClick={() => onResend()}
                  data-test-id="Onboarding-VerificationCode-Resent"
                >
                  {t(`onboarding.verifyPhone.newCode`)}
                </BlokkieButton>
                <BlokkieButton
                  variant="primary"
                  size="large"
                  disabled={disableButton()}
                  onClick={() => next()}
                  data-test-id="Onboarding-VerificationCode-NextButton"
                >
                  {t(`next`)}
                </BlokkieButton>
              </ButtonFooter>
            )}
          </div>
        </div>
      )}
      {isMobileOnly && (
        <>
          {!resendingSms && (
            <div style={{ marginTop: blokkieTheme.spacing(3) }}>
              <BlokkieButton
                variant="secondary"
                onClick={() => onResend()}
                data-test-id="Onboarding-VerificationCode-Resent"
                style={{ padding: 0 }}
              >
                {t('onboarding.verifyPhone.newCode')}
              </BlokkieButton>
            </div>
          )}
          <div>
            {isSmsResent && (
              <InfoBubble
                text={t('onboarding.verifyPhone.resentCode')}
                style={{ flex: 1, marginTop: blokkieTheme.spacing(2) }}
              />
            )}
          </div>
          {errorMessage && (
            <ErrorBubble
              errorMessage={errorMessage}
              style={{
                alignSelf: 'stretch',
                marginTop: blokkieTheme.spacing(2),
              }}
            />
          )}
          <ButtonFooter
            align="right"
            customStyle={{
              flexDirection: 'column',
              justifyContent: 'flex-end',
              flex: 1,
              gap: blokkieTheme.spacing(2),
            }}
          >
            {!resendingSms && (
              <BlokkieButton
                variant="primary"
                size="large"
                disabled={disableButton()}
                onClick={() => next()}
                data-test-id="Onboarding-VerificationCode-NextButton"
              >
                {t(`next`)}
              </BlokkieButton>
            )}
          </ButtonFooter>
        </>
      )}
    </Wrapper>
  );
}

const useStyles = createUseStyles({
  verifyPhoneBodyWeb: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: blokkieTheme.spacing(2),
    flexGrow: '1',
  },
});

export default injectIntl(VerificationCode);
