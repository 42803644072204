import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/arrow-back-white.svg';
import { BlokkieIcon, BlokkieTextLink, blokkieTheme } from '@tikkie/blokkie';
import messages from './messages';

function HeaderComponent(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerInner}>
        <BlokkieTextLink
          href="https://www.tikkie.me/"
          variant="pl700"
          color={blokkieTheme.colors.grey.white}
          underline={false}
        >
          <div className={classes.textLinkInner}>
            <BlokkieIcon
              variant="arrowLeft"
              color={blokkieTheme.colors.grey.white}
            />
            <FormattedMessage {...messages.back} />
          </div>
        </BlokkieTextLink>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  header: {
    height: '72px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'none',
    },
  },
  headerInner: {
    width: '1031px',
    display: 'flex',
    alignItems: 'center',
    [blokkieTheme.mediaQueries.tablet]: {
      width: '95%',
    },
  },
  textLinkInner: {
    display: 'flex',
    flexDirection: 'row',
    gap: blokkieTheme.spacing(1),
  },
});

export default HeaderComponent;
