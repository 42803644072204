import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { BrowserRouter } from 'react-router-dom';
import { BlokkieSnackbarProvider, BlokkieThemeProvider } from '@tikkie/blokkie';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import ReactGA from 'react-ga4';
import localization from 'moment/locale/nl';
import 'sanitize.css/sanitize.css';
import 'font-awesome/css/font-awesome.min.css';
import AppContainer from './containers/App/AppContainer';
import LanguageProvider from './containers/LanguageProvider';
import configureStore from './configureStore';
import theme from './theme';
import { translationMessages } from './i18n';
import { GlobalStyle } from './global-styles';
import history from './utils/history';
import { analyticsPageview, measurementId } from './ga4';
import './i18n/i18n';

// Create redux store with history
const initialState = {};
const { store } = configureStore(initialState, history);

function App() {
  moment.updateLocale('nl', localization);
  ReactGA.initialize(measurementId);

  useEffect(() => {
    analyticsPageview();
  }, []);

  return (
    <BlokkieThemeProvider theme="business">
      <BlokkieSnackbarProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Provider store={store}>
            <BrowserRouter>
              <ConnectedRouter history={history}>
                <LanguageProvider messages={translationMessages}>
                  <AppContainer />
                </LanguageProvider>
              </ConnectedRouter>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </BlokkieSnackbarProvider>
    </BlokkieThemeProvider>
  );
}

export default App;
