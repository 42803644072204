import config from '../../config';
import {
  ACCEPT_TERMS,
  ACCEPT_TERMS_FOR_SECOND_APPLICANT,
  CHECK_BUSINESS_PAYMENT,
  CHECK_PERSONAL_PAYMENT,
  CONFIRM_EMAIL,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FOR_SECOND_APPLICANT,
  GET_CURRENT_ITEM,
  GET_IDV_STATUS,
  GET_IDV_STATUS_FOR_SECOND_APPLICANT,
  GET_ISSUERS,
  GET_ITEM_AFTER_IDV,
  GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT,
  GET_POS_DATA,
  GET_TERMS,
  INITIATE_BUSINESS_PAYMENT,
  INITIATE_PERSONAL_PAYMENT,
  RESEND_SMS,
  RESET_PASSWORD,
  SECOND_APPLICANT_DETAILS,
  SEND_BSN,
  SEND_EMAIL,
  SEND_RISK_ANSWERS,
  SEND_SMS,
  SET_AUTHORIZED_TO_SIGN,
  SET_BIRTH_DATE,
  SET_CONTACT_NAME,
  SET_COUNTRY,
  SET_COUNTRY_FOR_SECOND_APPLICANT,
  SET_KEEP_IN_TOUCH,
  SET_NICKNAME,
  SET_PASSWORD,
  SET_RISK_ANSWERS,
  SET_SECTOR,
  SKIP_BUSINESS_PAYMENT,
  START_ORGANISATION_ONBOARDING,
  SUBMIT_ADDRESS,
  SUBMIT_ADDRESS_FOR_SECOND_APPLICANT,
  SUBMIT_PARTIAL_ADDRESS,
  SUBMIT_POS_DATA,
  SUBMIT_UBO,
  VALIDATE_SECOND_APPLICANT_TOKEN,
  VERIFY_CODE,
} from './constants';

const firstOnboardingItem = 'ORGANISATION';

const onboardingItems = {
  ORGANISATION: {
    path: '/onboarding-kyc',
  },
  AUTHORIZED_TO_SIGN: {
    path: '/onboarding-kyc/authorized-to-sign',
  },
  AUTHORIZED_TO_SIGN_SIMPLIFIED: {
    path: '/onboarding-kyc/authorized-to-sign-simplified',
  },
  SECOND_APPLICANT_DETAILS: {
    path: '/onboarding-kyc/second-applicant-details',
  },
  POS_SYSTEM: {
    path: '/onboarding-kyc/pos-system',
  },
  NICKNAME: {
    path: '/onboarding-kyc/nickname',
  },
  EMAIL_VERIFICATION: {
    path: '/onboarding-kyc/email',
  },
  USER_PASSWORD: {
    path: '/onboarding-kyc/confirm-email/:jwt-token',
  },
  USER_PHONE: {
    path: '/onboarding-kyc/verify-phone',
  },
  SET_SECTOR: {
    path: '/onboarding-kyc/sector',
  },
  SET_CONTACT_NAME: {
    path: '/onboarding-kyc/set-contact-name',
  },
  SET_BIRTH_DATE: {
    path: '/onboarding-kyc/birth-date',
  },
  SET_BSN: {
    path: '/onboarding-kyc/bsn',
  },
  SET_COUNTRY: {
    path: '/onboarding-kyc/country',
  },
  SET_ADDRESS: {
    path: '/onboarding-kyc/address',
  },
  SET_RISK_ANSWERS: {
    path: '/onboarding-kyc/risk-questions',
  },
  PERSONAL_PAYMENT: {
    path: '/onboarding-kyc/contact-payment',
  },
  UBO: {
    path: '/onboarding-kyc/ubo',
  },
  BUSINESS_PAYMENT: {
    path: '/onboarding-kyc/business-payment',
  },
  IDV: {
    path: '/onboarding-kyc/idv',
  },
  TERMS: {
    path: '/onboarding-kyc/terms',
  },
  SIGN_UP_PENDING: {
    path: '/onboarding-kyc/sign-up-pending',
  },
  SECOND_APPLICANT_START: {
    path: '/onboarding-kyc/second-applicant/start/:token',
  },
  SECOND_APPLICANT_ADDRESS: {
    path: '/onboarding-kyc/second-applicant/address',
  },
  SECOND_APPLICANT_IDV: {
    path: '/onboarding-kyc/second-applicant/idv',
  },
  SECOND_APPLICANT_TERMS: {
    path: '/onboarding-kyc/second-applicant/terms',
  },
  SECOND_APPLICANT_END: {
    path: '/onboarding-kyc/second-applicant/end',
  },
};

const onboardingEndpoints = {
  [CONFIRM_EMAIL]: 'emailverification',
  [VALIDATE_SECOND_APPLICANT_TOKEN]: 'secondapplicant/validatetoken',
  [GET_CURRENT_ITEM]: 'currentitem',
  [START_ORGANISATION_ONBOARDING]: 'startorganisationonboarding',
  [SEND_EMAIL]: 'initiateemailverification',
  [SET_PASSWORD]: 'password',
  [SET_SECTOR]: 'sector',
  [SET_CONTACT_NAME]: 'contactpersonname',
  [SEND_SMS]: 'initiatephonenumberverification',
  [RESEND_SMS]: 'resendphonenumberverification',
  [SET_BIRTH_DATE]: 'birthdate',
  [SEND_BSN]: 'bsn',
  [VERIFY_CODE]: 'phonenumberverification',
  [GET_TERMS]: 'terms/type',
  [SET_COUNTRY]: 'country',
  [SET_COUNTRY_FOR_SECOND_APPLICANT]: 'secondapplicant/country',
  [SUBMIT_PARTIAL_ADDRESS]: 'possibleaddresses',
  [SET_AUTHORIZED_TO_SIGN]: 'authorizedtosign',
  [SECOND_APPLICANT_DETAILS]: 'secondapplicantdetails',
  [SUBMIT_ADDRESS]: 'address',
  [SUBMIT_ADDRESS_FOR_SECOND_APPLICANT]: 'secondapplicant/address',
  [GET_ISSUERS]: 'issuers',
  [ACCEPT_TERMS]: 'acceptterms',
  [ACCEPT_TERMS_FOR_SECOND_APPLICANT]: 'secondapplicant/acceptterms',
  [INITIATE_PERSONAL_PAYMENT]: 'initiatepersonalpayment',
  [CHECK_PERSONAL_PAYMENT]: 'checkpersonalpayment',
  [SKIP_BUSINESS_PAYMENT]: 'skipbusinesspayment',
  [INITIATE_BUSINESS_PAYMENT]: 'initiatebusinesspayment',
  [RESET_PASSWORD]: 'resetpassword',
  [CHECK_BUSINESS_PAYMENT]: 'checkbusinesspayment',
  [SET_NICKNAME]: 'nickname',
  [SUBMIT_UBO]: 'ubo',
  [SUBMIT_POS_DATA]: 'possystem',
  [SET_KEEP_IN_TOUCH]: 'keepinformed',
  [GET_POS_DATA]: 'posdata',
  [CREATE_TRANSACTION]: 'createidvtransaction',
  [CREATE_TRANSACTION_FOR_SECOND_APPLICANT]:
    'secondapplicant/createidvtransaction',
  [GET_IDV_STATUS]: 'idvstatus',
  [GET_IDV_STATUS_FOR_SECOND_APPLICANT]: 'secondapplicant/idvstatus',
  [GET_ITEM_AFTER_IDV]: 'transactioncompleted',
  [GET_ITEM_AFTER_IDV_FOR_SECOND_APPLICANT]:
    'secondapplicant/transactioncompleted',
  [SEND_RISK_ANSWERS]: 'riskanswers',
};

export const getOnboardingEndpoint = (key) =>
  `${config.ONBOARDING_SERVICE_URL}/${onboardingEndpoints[key]}`;
export const getOnboardingPath = (onboardingItem) =>
  onboardingItems[onboardingItem].path;
export const getFirstOnboardingPath = () =>
  onboardingItems[firstOnboardingItem].path;

const pending = 'PENDING';
const paid = 'PAID';
const notPaid = 'NOT_PAID';

export const paymentStatuses = {
  pending,
  paid,
  notPaid,
};

export const ABN_AMRO_BANK_CODE = 'ABNANL2A';
export const REVOLUT_BANK_CODE = 'REVOLT21';
export const tikkieRecycling = 'TIKKIE_RECYCLING';
export const tikkieCashback = 'TIKKIE_CASHBACK';
export const tikkieBusiness = 'TIKKIE_ALL';
export const simplifiedDueDiligenceProducts = [tikkieCashback];
export const productTypeToTermsMap = new Map([
  [tikkieBusiness, 'BUSINESS'],
  [tikkieRecycling, 'RECYCLING'],
  [tikkieCashback, 'CASHBACK'],
]);
export const productTypeToLokaliseTag = new Map([
  [tikkieBusiness, 'business'],
  [tikkieRecycling, 'recycling'],
  [tikkieCashback, 'cashback'],
]);
export const productTypeToProductInfoUrlMap = new Map([
  [tikkieBusiness, 'https://www.tikkie.me/zakelijk'],
  [tikkieRecycling, 'https://www.tikkie.me/zakelijk/statiegeld'],
  [tikkieCashback, 'https://www.tikkie.me/zakelijk/cashback'],
]);
