import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/close-icon.svg';
import { BlokkieButton, BlokkieTextLink } from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import {
  OnboardingParagraph,
  RadioButtonContainer,
  Wrapper,
} from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import AuthorizedToSignInvalid from '../ErrorScreen/AuthorizedToSignInvalid';
import { useOnboardingPageView } from '../../../hooks';
import {
  OnboardingFlow,
  onboardingFlowToHashMapping,
} from '../../../containers/OnboardingKYCContainer/onboardingFlow';
import Title from '../Title';

const ONBOARDING_KYC_URL = 'https://business.tikkie.me/onboarding-kyc';

interface AuthorizedToSignProps {
  setAuthorizedData(state: {
    authorizedToSign: boolean;
    jointlyAuthorized?: boolean;
  }): void;
  previousStep(): void;
  organisationName: string;
  authorizedData?: {
    authorizedToSign: boolean;
    jointlyAuthorized: boolean;
  };
  isAbnOnboarding?: boolean;
  isAabOnboarding?: boolean;
  isCddFlow?: boolean;
  onboardingFlow?: OnboardingFlow;
}

function AuthorizedToSign({
  setAuthorizedData,
  previousStep,
  organisationName,
  authorizedData,
  isAbnOnboarding,
  isAabOnboarding,
  isCddFlow,
  onboardingFlow,
}: AuthorizedToSignProps): JSX.Element {
  const { t } = useTranslation();
  const [info, setInfo] = useState(false);
  const [invalidChoice, setInvalidChoice] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [authorizedToSign, setAuthorizedToSign] = useState(
    authorizedData ? authorizedData.authorizedToSign : false,
  );
  const [jointlyAuthorized, setJointlyAuthorized] = useState(
    authorizedData ? authorizedData.jointlyAuthorized : false,
  );

  useOnboardingPageView('authorized-to-sign');

  const onboardingUrlSuffix =
    onboardingFlowToHashMapping.get(onboardingFlow ?? OnboardingFlow.BASIC) ??
    '';

  const onboardingUrl = `${ONBOARDING_KYC_URL}${onboardingUrlSuffix}`;

  const showInfo = (): void => setInfo(!info);
  const goNext = (): void => {
    if (!authorizedToSign) {
      setInvalidChoice(true);
    } else if (!jointlyAuthorized) {
      setAuthorizedData({ authorizedToSign });
    } else {
      setAuthorizedData({
        authorizedToSign: true,
        jointlyAuthorized: true,
      });
    }
  };
  const enableSoleAuthorized = (): void => {
    setAuthorizedToSign(true);
    setJointlyAuthorized(false);
    setUntouched(false);
  };
  const enableJointlyAuthorized = (): void => {
    setAuthorizedToSign(true);
    setJointlyAuthorized(true);
    setUntouched(false);
  };
  const enableNotAuthorized = (): void => {
    setAuthorizedToSign(false);
    setJointlyAuthorized(false);
    setUntouched(false);
  };
  const dismissError = (): void => {
    setInvalidChoice(false);
  };

  const isNextButtonDisabled = (): boolean => {
    if (authorizedToSign) return false;
    if (!untouched) return false;
    return true;
  };

  return (
    <>
      {invalidChoice && (
        <AuthorizedToSignInvalid
          organisationName={organisationName}
          onboardingUrl={onboardingUrl}
          dismissError={dismissError}
        />
      )}

      {!invalidChoice && (
        <>
          <Wrapper>
            <NavigationHeader
              clickLeft={previousStep as () => void}
              clickRight={showInfo}
            />
            <Title>{`${t(
              'onboarding.authorizedToSignScreen.title',
            )} ${organisationName}?`}</Title>
            <RadioButtonContainer>
              <OnboardingRadioButton
                key="authorizedToSign"
                id="authorizedToSign"
                onClick={enableSoleAuthorized}
                checked={authorizedToSign && !jointlyAuthorized}
                text={t('onboarding.authorizedToSignScreen.allowed')}
                data-test-id="Onboarding-AuthorizedToSign-Yes"
              />
              {!isAbnOnboarding && !isAabOnboarding && !isCddFlow && (
                <OnboardingRadioButton
                  key="jointlyAuthorizedToSign"
                  id="jointlyAuthorizedToSign"
                  onClick={enableJointlyAuthorized}
                  checked={authorizedToSign && jointlyAuthorized}
                  text={t('onboarding.authorizedToSignScreen.jointlyAllowed')}
                  data-test-id="Onboarding-AuthorizedToSign-Yes-Jointly"
                />
              )}
              <OnboardingRadioButton
                key="notAuthorizedToSign"
                id="notAuthorizedToSign"
                onClick={enableNotAuthorized}
                checked={!authorizedToSign && !untouched}
                text={t('onboarding.authorizedToSignScreen.notAllowed')}
                data-test-id="Onboarding-AuthorizedToSign-No"
              />
            </RadioButtonContainer>
            <ButtonFooter>
              <BlokkieButton
                onClick={goNext}
                data-test-id="Onboarding-AuthorizedToSign-NextButton"
                variant="primary"
                size="large"
                disabled={isNextButtonDisabled()}
              >
                {t('next')}
              </BlokkieButton>
            </ButtonFooter>
          </Wrapper>
          <InfoScreen
            show={info}
            title={t('onboarding.authorizedToSignScreen.infoTitle')}
            onClick={showInfo}
            fromPage={AuthorizedToSign.name}
          >
            {(isAbnOnboarding || isAabOnboarding) && (
              <>
                <OnboardingParagraph>
                  {t('onboarding.authorizedToSignScreen.aabABN.infoBody1')}
                </OnboardingParagraph>
                <OnboardingParagraph>
                  {t('onboarding.authorizedToSignScreen.aabABN.infoBody2')}
                  <span>&nbsp;&nbsp;</span>
                  <BlokkieTextLink
                    href="https://www.abnamro.nl/nl/zakelijk/service-contact/index/path-detail/Gegevens-wijzigen/Tekenbevoegde.html"
                    target="_blank"
                    variant="pl700"
                    underline
                  >
                    {t(`onboarding.authorizedToSignScreen.aabABN.infoLink`)}
                  </BlokkieTextLink>
                </OnboardingParagraph>
              </>
            )}
            {!isAbnOnboarding && !isAabOnboarding && (
              <>
                <OnboardingParagraph>
                  {t('onboarding.authorizedToSignScreen.infoBody1')}
                </OnboardingParagraph>
                <OnboardingParagraph>
                  {t('onboarding.authorizedToSignScreen.infoBody2')}
                </OnboardingParagraph>
              </>
            )}
          </InfoScreen>
        </>
      )}
    </>
  );
}

export default injectIntl(AuthorizedToSign);
