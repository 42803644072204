import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Terms from '../../../components/OnboardingKYC/Terms';
import FormWrapperContainer from '../FormWrapperContainer';
import { acceptTerms, getTerms } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectFlow,
  selectIdvCheckStatus,
  selectIsGettingTerms,
  selectIsSecondApplicant,
  selectProductType,
  selectTerms,
} from '../selectors';
import { productTypeToTermsMap } from '../config';
import Summary from '../../../components/OnboardingKYC/Summary/Summary';
import WaitingScreen from '../../../components/OnboardingKYC/Terms/waitingScreen';
import { Flow } from '../../../components/OnboardingKYC/enums/Flow';

function TermsContainer(props) {
  const { isSecondApplicant, productType } = props;
  const termsType = productTypeToTermsMap.get(productType);

  const flow = useSelector(selectFlow);
  const idvCheckStatus = useSelector(selectIdvCheckStatus);

  const isIdvCheckStatusPassed = idvCheckStatus === 'PASSED';
  const isCddFlow = flow === Flow.BUSINESS_CDD;

  const showSummaryScreen = isCddFlow && isIdvCheckStatusPassed;
  const showWaitScreen = isCddFlow && !isIdvCheckStatusPassed;

  if (showWaitScreen) {
    return (
      <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
        <WaitingScreen />
      </FormWrapperContainer>
    );
  }

  if (showSummaryScreen) {
    return (
      <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
        <Summary
          terms={props.terms}
          isGettingTerms={props.isGettingTerms}
          getTerms={() => props.getTerms(termsType)}
          acceptTerms={() => props.acceptTerms(isSecondApplicant)}
        />
      </FormWrapperContainer>
    );
  }

  return (
    <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
      <Terms
        terms={props.terms}
        isGettingTerms={props.isGettingTerms}
        getTerms={() => props.getTerms(termsType)}
        acceptTerms={() => props.acceptTerms(isSecondApplicant)}
      />
    </FormWrapperContainer>
  );
}

TermsContainer.propTypes = {
  getTerms: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  isGettingTerms: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  isSecondApplicant: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  terms: selectTerms,
  isGettingTerms: selectIsGettingTerms,
  isSecondApplicant: selectIsSecondApplicant,
  productType: selectProductType,
});

const mapDispatchToProps = (dispatch) => ({
  getTerms: (type) => dispatch(getTerms(type)),
  acceptTerms: (forSecondApplicant) =>
    dispatch(acceptTerms(forSecondApplicant)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(TermsContainer);
