import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import 'url-search-params-polyfill';
import { DAEMON } from '../../utils/constants';
import injectSaga from '../../utils/injectSaga';
import { SECOND_APPLICANT } from './IDVContainer/constants';
import {
  clearSession,
  confirmEmail,
  getCurrentItem,
  getItemAfterIdv,
  setAabOnboarding,
  setBusinessPaymentToken,
  setInitialGetCurrentItem,
  setOnboardingFlow,
  setPersonalPaymentToken,
  setProductType,
  setResetPasswordToken,
  setSecondApplicant,
  validateSecondApplicantToken,
  setUrlHash,
} from './actions';
import {
  getFirstOnboardingPath,
  tikkieBusiness,
  tikkieRecycling,
  tikkieCashback,
} from './config';
import saga from './saga';
import {
  selectCurrentItemNumber,
  selectInitialGetCurrentItem,
  selectIsSecondApplicant,
  selectTotalItems,
} from './selectors';
import {
  hashToOnboardingFlowMapping,
  OnboardingFlow,
  OnboardingHash,
} from './onboardingFlow';

const getConsequentStep = (stepsArr, currentStep, increment) => {
  const currentStepIndex = stepsArr.findIndex((key) => key === currentStep);
  const consequentStep = stepsArr[currentStepIndex + increment];

  if (!consequentStep) {
    return currentStep;
  }
  return consequentStep;
};

function onboardingKYCContainerHOC(OnboardingKYCContainer) {
  function OnboardingKYCContainerHOC(props) {
    const match = useRouteMatch();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
      const { initialGetCurrentItem } = props;
      const urlParams = new URLSearchParams(location.search);
      const { path, params } = match;

      if (path === getFirstOnboardingPath()) {
        dispatch(
          setOnboardingFlow(
            hashToOnboardingFlowMapping.get(location.hash) ??
              OnboardingFlow.UNDETERMINED,
          ),
        );

        props.setAabOnboarding(location.hash === OnboardingHash.AAB_ONBOARDING);

        const isValidHash = Object.values(OnboardingHash).some(
          (value) => value === location.hash,
        );

        dispatch(setUrlHash(isValidHash ? location.hash : OnboardingHash.NONE));

        if (location.hash === OnboardingHash.RECYCLING) {
          dispatch(setProductType(tikkieRecycling));
        } else if (location.hash === OnboardingHash.CASHBACK) {
          dispatch(setProductType(tikkieCashback));
        } else {
          dispatch(setProductType(tikkieBusiness));
        }
      }

      if (path.includes(SECOND_APPLICANT)) {
        dispatch(setOnboardingFlow(OnboardingFlow.SECOND_APPLICANT));
        if (!props.isSecondApplicant) {
          props.setSecondApplicant(true);
        }
      }

      if (path.includes('/confirm-email')) {
        props.confirmEmail(params.token);
        return;
      } else if (path.includes('/contact-payment')) {
        if (params.paymentToken) {
          props.setPersonalPaymentToken(params.paymentToken);
        }
        props.getCurrentItem();
        return;
      } else if (path.includes('/reset/')) {
        props.setResetPasswordToken(params.token);
        return;
      } else if (path.includes('/business-payment')) {
        if (params.paymentToken) {
          props.setBusinessPaymentToken(params.paymentToken);
        }
        props.getCurrentItem();
        return;
      } else if (path.includes('/ubo')) {
        props.getCurrentItem();
        return;
      } else if (path.includes('/idv-return/')) {
        props.getItemAfterIdv(
          params.transactionId,
          path.includes(SECOND_APPLICANT),
        );
        return;
      } else if (
        path.includes('/second-applicant/start') &&
        params.token !== undefined
      ) {
        props.validateSecondApplicantToken(params.token);
        return;
      }

      // In case of hitting entry point without logging in a new session should start
      if (path === '/onboarding-kyc') {
        if (urlParams.get('loggedIn')) {
          props.getCurrentItem();
          return;
        }
        props.clearSession();
        return;
      }

      if (initialGetCurrentItem) {
        props.getCurrentItem();
      }
    }, []);

    const getNextStep = (steps, currentStep) =>
      getConsequentStep(steps, currentStep, 1);

    const getPreviousStep = (steps, currentStep) =>
      getConsequentStep(steps, currentStep, -1);

    const getProgressPercentage = (totalItems, currentItemNumber) =>
      Math.floor((currentItemNumber / totalItems) * 100);

    const progressPercentage = getProgressPercentage(
      props.totalItems,
      props.currentItemNumber,
    );

    return (
      <OnboardingKYCContainer
        getNextStep={getNextStep}
        getPreviousStep={getPreviousStep}
        progressPercentage={progressPercentage}
        {...props}
      />
    );
  }

  OnboardingKYCContainerHOC.propTypes = {
    initialGetCurrentItem: PropTypes.bool.isRequired,
    setInitialGetCurrentItem: PropTypes.func.isRequired,
    getCurrentItem: PropTypes.func.isRequired,
    confirmEmail: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    setPersonalPaymentToken: PropTypes.func.isRequired,
    setResetPasswordToken: PropTypes.func.isRequired,
    setBusinessPaymentToken: PropTypes.func.isRequired,
    currentItemNumber: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    clearSession: PropTypes.func.isRequired,
    location: PropTypes.object,
    setAabOnboarding: PropTypes.func.isRequired,
    setRecyclingOnboarding: PropTypes.func.isRequired,
    setCashbackOnboarding: PropTypes.func.isRequired,
    getItemAfterIdv: PropTypes.func.isRequired,
    validateSecondApplicantToken: PropTypes.func.isRequired,
    isSecondApplicant: PropTypes.bool,
    setSecondApplicant: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) => ({
    getCurrentItem: (noRedirect) => dispatch(getCurrentItem(noRedirect)),
    confirmEmail: (token) => dispatch(confirmEmail(token)),
    setPersonalPaymentToken: (paymentToken) =>
      dispatch(setPersonalPaymentToken(paymentToken)),
    setResetPasswordToken: (token) => dispatch(setResetPasswordToken(token)),
    setBusinessPaymentToken: (paymentToken) =>
      dispatch(setBusinessPaymentToken(paymentToken)),
    clearSession: () => dispatch(clearSession()),
    setInitialGetCurrentItem: (boolean) =>
      dispatch(setInitialGetCurrentItem(boolean)),
    setAabOnboarding: (isAabOnboarding) =>
      dispatch(setAabOnboarding(isAabOnboarding)),
    getItemAfterIdv: (transactionId, forSecondApplicant) =>
      dispatch(getItemAfterIdv(transactionId, forSecondApplicant)),
    validateSecondApplicantToken: (token) =>
      dispatch(validateSecondApplicantToken(token)),
    setSecondApplicant: (isSecondApplicant) =>
      dispatch(setSecondApplicant(isSecondApplicant)),
  });

  const mapStateToProps = createStructuredSelector({
    initialGetCurrentItem: selectInitialGetCurrentItem,
    currentItemNumber: selectCurrentItemNumber,
    totalItems: selectTotalItems,
    isSecondApplicant: selectIsSecondApplicant,
  });

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const withSaga = injectSaga({
    key: 'OnboardingKYCContainerHOC',
    saga,
    mode: DAEMON,
  });

  return compose(withSaga, withConnect)(OnboardingKYCContainerHOC);
}
export default onboardingKYCContainerHOC;
